import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import useTheme from "@mui/material/styles/useTheme";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import DialogAppBar from "../components/DialogAppBar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { useQuery, useMutation } from "react-query";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import {
  getRequestUI,
  postRequestUI,
  putRequestUI,
} from "common-utils/utils/api";
import { ButtonProgress, SharedStyles } from "@shared/components/lib/index";
import _ from "lodash";
import { Snackbar, Alert } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CarrierHubsForm = ({ hub, carrierId, onSave, handleClose }) => {
  const theme = useTheme();
  const sharedStyles = SharedStyles(theme);
  const [carrierHubData, setCarrierHubData] = useState(hub);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [buttonText, setButtonText] = useState("Save Hub");
  const [formHeader, setFormHeader] = useState("Create New Hub");

  const prerequisites = useQuery(
    ["admin-carrier-hubs-prerequisites"],
    async () => {
      const result = await getRequestUI("/admin/location-prerequisites");
      return result;
    },
    {
      onError: (err) => {
        setErrorMessage(err.message);
      },
    },
  );
  const { countries = [] } = prerequisites.data ? prerequisites.data : {};

  const validate = () => {
    if (!carrierHubData.name) {
      setErrorMessage("Hub name is required");
      setShowError(true);
      return false;
    }
    if (!carrierHubData.postcode) {
      setErrorMessage("Postcode is required");
      setShowError(true);
      return false;
    }
    if (!carrierHubData.houseNumber) {
      setErrorMessage("House number is required");
      setShowError(true);
      return false;
    }
    if (_.isEmpty(carrierHubData.country)) {
      setErrorMessage("Country is required");
      setShowError(true);
      return false;
    }
    return true;
  };

  const handleAddCarrierHub = () => {
    if (!validate()) {
      return;
    }
    const data = {
      name: carrierHubData.name,
      carrierId,
      isDefault: carrierHubData.isDefault,
      address: {
        postcode: carrierHubData.postcode,
        houseNumber: carrierHubData.houseNumber,
        houseAdditions: carrierHubData.houseAdditions,
        country: carrierHubData.country,
        coordinates: carrierHubData.coordinates,
      },

      city: carrierHubData.city,
      street: carrierHubData.street,
    };
    createCarrierHubsMutation.mutate(data);
  };

  const handleUpdateCarrierHub = () => {
    if (!validate()) {
      return;
    }
    const data = {
      id: carrierHubData.id,
      name: carrierHubData.name,
      carrierId,
      isDefault: carrierHubData.isDefault,
      address: {
        postcode: carrierHubData.postcode,
        houseNumber: carrierHubData.houseNumber,
        houseAdditions: carrierHubData.houseAdditions,
        country: carrierHubData.country,
        coordinates: carrierHubData.coordinates,
      },
      city: carrierHubData.city,
      street: carrierHubData.street,
    };
    updateCarrierHubsMutation.mutate(data);
  };

  const createCarrierHubsMutation = useMutation(createCarrierHub, {
    onSuccess: () => {
      onSave();
      handleClose();
    },
    onError: (error) => {
      if (error.code === "invalid-argument") {
        setErrorMessage(error.message || "An error occurred");
        setShowError(true);
      }
    },
    onSettled: () => {},
  });

  const updateCarrierHubsMutation = useMutation(updateCarrierHub, {
    onSuccess: () => {
      onSave();
      handleClose();
    },
    onError: (error) => {
      if (error.code === "invalid-argument") {
        setErrorMessage(error.message || "An error occurred");
        setShowError(true);
      }
    },
    onSettled: () => {},
  });

  async function createCarrierHub(data) {
    await postRequestUI(`carrier/hubs`, data);
  }

  async function updateCarrierHub(data) {
    await putRequestUI(`carrier/hubs/${data.id}`, data);
  }

  const handleCountry = (e, value) => {
    if (!value) {
      setCarrierHubData({ ...carrierHubData, country: {} });
    }
    setCarrierHubData({
      ...carrierHubData,
      country: value,
      city: "",
      street: "",
      postcode: "",
      houseNumber: "",
      houseAdditions: "",
    });
  };

  useEffect(() => {
    setFormHeader(hub ? "Edit Hub" : "Create New Hub");
  }, [carrierHubData, hub]);

  const submit = () => {
    if (carrierHubData.id) {
      handleUpdateCarrierHub();
    } else {
      handleAddCarrierHub();
    }
  };
  return (
    <>
      <Dialog
        fullScreen
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogAppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Button
              data-testid="add_hub_btn"
              autoFocus
              color="inherit"
              onClick={submit}
            >
              {buttonText}
            </Button>
          </Toolbar>
        </DialogAppBar>
        <DialogContent>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={showError}
            autoHideDuration={3000}
            onClose={() => setShowError(false)}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
          <form style={sharedStyles.container}>
            <FormControl sx={sharedStyles.formControl}>
              <Typography variant="h3" component="h4">
                {formHeader}
              </Typography>

              <TextField
                data-testid="name_field"
                id="name"
                variant="standard"
                label="Hub Name"
                value={carrierHubData?.name}
                onChange={(e) =>
                  setCarrierHubData({
                    ...carrierHubData,
                    name: e.target.value,
                  })
                }
              />
              <Autocomplete
                data-testid="countries_autocomplete"
                id="countries-autocomplete"
                autoComplete
                autoHighlight
                options={countries || []}
                value={carrierHubData?.country}
                getOptionLabel={(option) => option.name ?? ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Select Country"
                    placeholder="Select country"
                  />
                )}
                onChange={handleCountry}
              />

              <>
                <TextField
                  data-testid="postcode_field"
                  id="postcode-field"
                  variant="standard"
                  label="Postcode"
                  value={carrierHubData?.postcode}
                  onChange={(e) =>
                    setCarrierHubData({
                      ...carrierHubData,
                      postcode: e.target.value,
                    })
                  }
                />
                <TextField
                  data-testid="house_number_field"
                  id="house-number-field"
                  variant="standard"
                  label="House Number"
                  value={carrierHubData?.houseNumber}
                  onChange={(e) =>
                    setCarrierHubData({
                      ...carrierHubData,
                      houseNumber: e.target.value,
                    })
                  }
                />
                <TextField
                  data-testid="house_addition_field"
                  id="house-addition"
                  variant="standard"
                  label='Addition (e.g. "H" or "1")'
                  value={carrierHubData?.houseAdditions}
                  onChange={(e) =>
                    setCarrierHubData({
                      ...carrierHubData,
                      houseAdditions: e.target.value,
                    })
                  }
                />
                <TextField
                  data-testid="street_field"
                  id="street_field"
                  variant="standard"
                  label="Street"
                  value={carrierHubData?.street}
                  onChange={(e) =>
                    setCarrierHubData({
                      ...carrierHubData,
                      street: e.target.value,
                    })
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={carrierHubData?.isDefault}
                      onChange={(e) =>
                        setCarrierHubData({
                          ...carrierHubData,
                          isDefault: e.target.checked,
                        })
                      }
                      name="isDefault"
                    />
                  }
                  label="Default Hub"
                />
              </>
            </FormControl>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CarrierHubsForm;
