import Orders from "./pages/Orders";
import Retailers from "./pages/Retailers";
import Locations from "./pages/Locations";
import Users from "./pages/Users";
import Carriers from "./pages/Carriers";
import PaazlOrders from "./pages/PaazlOrders";
import Sizes from "./pages/Sizes";
import Claims from "./pages/Claims";
import OrderExports from "./pages/Exports";
import BillingExports from "./pages/BillingExports";
import Holidays from "./pages/Holidays";

export const SUPPORT_PAGES = [
  { path: "/orders", name: "Orders", element: <Orders /> },
];

export const PAGES = [
  ...SUPPORT_PAGES,
  { path: "/retailers", name: "Retailers", element: <Retailers /> },
  { path: "/carriers", name: "Carriers", element: <Carriers /> },
  { path: "/locations", name: "Locations", element: <Locations /> },
  { path: "/users", name: "Users", element: <Users /> },
  { path: "/paazl", name: "Paazl Orders", element: <PaazlOrders /> },
  { path: "/sizes", name: "Sizes", element: <Sizes /> },
  { path: "/claims", name: "Claims", element: <Claims /> },
  { path: "/exports", name: "Exports", element: <OrderExports /> },
  {
    path: "/billing-exports",
    name: "Billing Exports",
    element: <BillingExports />,
  },
  {
    path: "/holidays",
    name: "Holidays",
    element: <Holidays />,
  },
];
