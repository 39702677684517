import React, { useState } from "react";
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Snackbar,
  FormControl,
  IconButton,
  Paper,
  TableContainer,
  Slide,
  Toolbar,
  Alert,
  CircularProgress,
  LinearProgress,
  Grid,
} from "@mui/material";
import {
  Add as AddIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getRequestUI,
  postRequestUI,
  putRequestUI,
  deleteRequestUI,
} from "common-utils/utils/api";
import {
  TableComponent,
  AddButton,
  PageTitle,
  MainBox,
  SharedStyles,
  DialogAppBar,
  ButtonProgress,
  DateFormatter,
} from "@shared/components/lib/index";
import useTheme from "@mui/material/styles/useTheme";
import { DateRange } from "react-date-range";
import { DateTime } from "luxon";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const useStyles = (theme) => ({
  deleteProgress: {
    marginLeft: 10,
  },
  mainBox: {
    padding: 0,
  },
  formContainer: {
    marginTop: theme.spacing(3),
    maxWidth: 540,
  },
});

const Holidays = () => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    dateRange: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  });
  const [isEdit, setIsEdit] = useState(false);
  const [selectedHolidayId, setSelectedHolidayId] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const queryClient = useQueryClient();
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);

  const {
    data: holidays = [],
    isLoading,
    error: fetchError,
  } = useQuery(
    ["holidays"],
    async () => {
      const result = await getRequestUI("/holidays");
      return result;
    },
    {
      onError: () => setSnackbarMessage("Unable to retrieve holidays."),
    },
  );

  const holidayMutation = useMutation(
    (holiday) =>
      isEdit
        ? putRequestUI(`/holidays/${selectedHolidayId}`, holiday)
        : postRequestUI("/holidays", holiday),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("holidays");
        setIsOpenDialog(false);
        resetFormData();
      },
      onError: (err) =>
        setSnackbarMessage(err.message || "Error saving holiday."),
    },
  );

  const deleteMutation = useMutation(
    (id) => deleteRequestUI(`/holidays/${id}`),
    {
      onSuccess: () => queryClient.invalidateQueries("holidays"),
      onError: (err) =>
        setSnackbarMessage(err.message || "Error deleting holiday."),
    },
  );

  const openEditDialog = (holiday) => {
    const startDate = DateTime.fromISO(holiday.startDate, {
      zone: "utc",
    }).toJSDate();
    const endDate = DateTime.fromISO(holiday.endDate, { zone: "utc" })
      .startOf("day")
      .toJSDate();

    setFormData({
      name: holiday.name,
      dateRange: {
        startDate,
        endDate,
        key: "selection",
      },
    });

    setSelectedHolidayId(holiday.id);
    setIsEdit(true);
    setIsOpenDialog(true);
  };
  const validateFilterDates = (startDate, endDate) => {
    return DateTime.fromJSDate(startDate) <= DateTime.fromJSDate(endDate);
  };

  const handleSaveHoliday = () => {
    const { name, dateRange } = formData;

    if (!name || !dateRange.startDate || !dateRange.endDate) {
      setSnackbarMessage("All fields are required");
      return;
    }

    if (!validateFilterDates(dateRange.startDate, dateRange.endDate)) {
      setSnackbarMessage("Start date cannot be after end date.");
      return;
    }

    holidayMutation.mutate({
      name,
      startDate: DateTime.fromJSDate(dateRange.startDate, {
        zone: "local",
      }).toISODate(),
      endDate: DateTime.fromJSDate(dateRange.endDate, {
        zone: "local",
      }).toISODate(),
    });
  };

  const handleDeleteHoliday = (id) => deleteMutation.mutate(id);

  const openCreateDialog = () => {
    resetFormData();
    setIsEdit(false);
    setIsOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const resetFormData = () => {
    setFormData({
      name: "",
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    });
  };

  const handleDateRangeChange = (ranges) => {
    setFormData({ ...formData, dateRange: ranges.selection });
  };

  return (
    <MainBox>
      <AddButton onClick={openCreateDialog} />
      {isLoading && <LinearProgress />}
      <TableContainer component={Paper} sx={sharedStyles.paper}>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"Holidays"} />
        </div>
        <TableComponent
          headers={["Name", "Start Date", "End Date", "Actions"]}
          rowData={holidays}
          cellComponents={[
            (holiday) => holiday.name,
            (holiday) => (
              <DateFormatter date={holiday.startDate} format={"dd-MM-yyyy"} />
            ),
            (holiday) => (
              <DateFormatter date={holiday.endDate} format={"dd-MM-yyyy"} />
            ),
            (holiday) => (
              <>
                <IconButton onClick={() => openEditDialog(holiday)}>
                  <EditIcon />
                </IconButton>
                {deleteMutation.isLoading ? (
                  <CircularProgress size={24} sx={styles.deleteProgress} />
                ) : (
                  <IconButton onClick={() => handleDeleteHoliday(holiday.id)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </>
            ),
          ]}
        />
      </TableContainer>

      <Dialog
        fullScreen
        open={isOpenDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <DialogAppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Button onClick={handleSaveHoliday} color="inherit" autoFocus>
              {isEdit ? "Update" : "Save"}
              {holidayMutation.isLoading && <ButtonProgress size={24} />}
            </Button>
          </Toolbar>
        </DialogAppBar>
        <DialogContent>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={!!snackbarMessage}
            autoHideDuration={3000}
            onClose={() => setSnackbarMessage("")}
          >
            <Alert severity="error">{snackbarMessage}</Alert>
          </Snackbar>
          <form style={styles.container}>
            <FormControl sx={sharedStyles.formControl}>
              <Typography variant="h3">
                {isEdit ? "Edit Holiday" : "Create New Holiday"}
              </Typography>
              <Grid container spacing={2} sx={styles.formContainer}>
                <Grid item xs={12}>
                  <TextField
                    label="Holiday Name"
                    fullWidth
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateRange
                    ranges={[formData.dateRange]}
                    onChange={handleDateRangeChange}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </form>
        </DialogContent>
      </Dialog>
    </MainBox>
  );
};

export default Holidays;
