import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import useTheme from "@mui/material/styles/useTheme";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  ButtonProgress,
  SharedStyles,
  MultiTextField,
} from "@shared/components/lib/index";

const useStyles = (theme) => ({
  formContainer: {
    marginTop: theme.spacing(3),
    maxWidth: 340,
  },
  submitBtn: {
    position: "absolute",
    top: "14px",
    zIndex: 10000,
    color: "#fff",
    left: "60px",
  },
});

const RetailerFormTab = ({
  retailer,
  edit,
  isCreatingNew,
  tagValue,
  handleEditRetailer,
  handleSave,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);
  const [retailerName, setRetailerName] = useState(null);
  const [retailerEmail, setRetailerEmail] = useState(null);
  const [notificationEmail, setNotificationEmail] = useState([]);
  const [exportReminderEmail, setExportReminderEmail] = useState(null);
  const [retailerContactNumber, setRetailerContactNumber] = useState(null);
  const [paazlClientNumber, setPaazlClientNumber] = useState(null);
  const [partner, setPartner] = useState(false);
  const [ageCheck, setAgeCheck] = useState(false);
  const [deliverAtNeighbors, setDeliverAtNeighbors] = useState(true);
  const [confirmOnOrderCreation, setConfirmOnOrderCreation] = useState(true);
  const [dataIngestOnly, setDataIngestOnly] = useState(false);
  const [retailerId, setRetailerId] = useState(null);

  useEffect(() => {
    if (edit) {
      setRetailerId(retailer.id);
      setRetailerName(retailer.name);
      setRetailerEmail(retailer.email);
      setNotificationEmail(retailer.notificationEmail);
      setRetailerContactNumber(retailer.number);
      setPartner(retailer.partner);
      setPaazlClientNumber(retailer.paazlClientNumber);
      setAgeCheck(retailer.ageCheck);
      setDeliverAtNeighbors(retailer.deliverAtNeighbors);
      setConfirmOnOrderCreation(retailer.confirmOnOrderCreation);
      setDataIngestOnly(retailer.dataIngestOnly);
      setExportReminderEmail(retailer.exportReminderEmail);
    }
  }, [edit]);

  const setButtonValue = () => {
    if (edit && tagValue === 0) {
      return "Update";
    } else if (tagValue === 1) {
      return "";
    } else {
      return "Save";
    }
  };

  const submit = () => {
    if (edit) {
      const data = {
        id: retailerId,
        name: retailerName,
        email: retailerEmail,
        notificationEmail: notificationEmail,
        number: retailerContactNumber,
        partner: partner,
        paazlClientNumber,
        ageCheck,
        deliverAtNeighbors,
        confirmOnOrderCreation,
        dataIngestOnly,
        exportReminderEmail,
      };
      return handleEditRetailer(data);
    } else {
      const data = {
        name: retailerName,
        email: retailerEmail,
        notificationEmail: notificationEmail,
        number: retailerContactNumber,
        partner: partner,
        paazlClientNumber,
        ageCheck,
        deliverAtNeighbors,
        confirmOnOrderCreation,
        dataIngestOnly,
        exportReminderEmail,
      };
      return handleSave(data);
    }
  };

  return (
    <>
      <Button
        data-testid="save_retailer_btn"
        sx={styles.submitBtn}
        autoFocus
        color="inherit"
        onClick={submit}
      >
        {setButtonValue()}
        {isCreatingNew && <ButtonProgress size={24} />}
      </Button>
      <form style={sharedStyles.container}>
        <FormControl sx={sharedStyles.formControl}>
          <Typography variant="h3" mb={2}>
            {edit ? "Edit Retailer" : "Create new Retailer"}
          </Typography>
          <TextField
            variant="standard"
            data-testid="retailer_name_field"
            id="name"
            label="Name"
            value={retailerName || ""}
            onChange={(e) => setRetailerName(e.target.value)}
          />
          <TextField
            variant="standard"
            data-testid="retailer_email_btn"
            id="email"
            label="Email"
            value={retailerEmail || ""}
            onChange={(e) => setRetailerEmail(e.target.value)}
          />
          <MultiTextField
            id="notification_email"
            label="Notification Email"
            type="search"
            fullWidth
            values={notificationEmail}
            setValues={setNotificationEmail}
          />
          <TextField
            variant="standard"
            id="export-reminder-email"
            data-testid="export-reminder_email_field"
            label="Export reminder Email"
            fullWidth
            value={exportReminderEmail || ""}
            onChange={(e) => setExportReminderEmail(e.target.value)}
          />
          <TextField
            variant="standard"
            data-testid="contact_number_field"
            id="contact-number"
            label="Contact number"
            value={retailerContactNumber || ""}
            onChange={(e) => setRetailerContactNumber(e.target.value)}
          />
          <TextField
            variant="standard"
            id="paazlClientNumber"
            label="Paazl client number"
            margin="normal"
            value={paazlClientNumber ? paazlClientNumber : ""}
            onChange={(e) => setPaazlClientNumber(e.target.value)}
          />
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={partner || false}
                  onChange={(e) => setPartner(e.target.checked)}
                  name="partner"
                  color="primary"
                />
              }
              label="Partner"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={ageCheck}
                  onChange={(e) => setAgeCheck(e.target.checked)}
                  name="ageCheck"
                  color="primary"
                />
              }
              label="Age Check"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={deliverAtNeighbors}
                  onChange={(e) => setDeliverAtNeighbors(e.target.checked)}
                  name="deliverAtNeighbors"
                  color="primary"
                />
              }
              label="Deliver at neighbor"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmOnOrderCreation}
                  onChange={(e) => setConfirmOnOrderCreation(e.target.checked)}
                  name="confirmOnOrderCreation"
                  color="primary"
                />
              }
              label="Auto-confirm orders"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dataIngestOnly}
                  onChange={(e) => setDataIngestOnly(e.target.checked)}
                  name="dataIngestOnly"
                  color="primary"
                />
              }
              label="Only ingest data"
            />
          </Grid>
        </FormControl>
      </form>
    </>
  );
};

export default RetailerFormTab;
