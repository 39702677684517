import React, { useState } from "react";
import {
  Button,
  TableContainer,
  Paper,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  IconButton,
  Toolbar,
  Grid,
  Alert,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Slide from "@mui/material/Slide";
import DialogAppBar from "./DialogAppBar";
import {
  getRequestUI,
  postRequestUI,
  deleteRequestUI,
} from "common-utils/utils/api";
import {
  TableComponent,
  SharedStyles,
  PageTitle,
} from "@shared/components/lib/index";
import useTheme from "@mui/material/styles/useTheme";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
const CarrierHolidaysTab = ({ carrierId }) => {
  const theme = useTheme();
  const sharedStyles = SharedStyles(theme);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState({});
  const [pickup, setPickup] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const queryClient = useQueryClient();

  const { data: carrierHolidays = [], isLoading: isFetchingCarrierHolidays } =
    useQuery(["carrierHolidays", carrierId], () =>
      getRequestUI(`/carrier/holidays/${carrierId}`),
    );

  const { isError: fetchHolidaysError } = useQuery(
    ["availableHolidays", carrierHolidays],
    async () => {
      const allHolidays = await getRequestUI("/holidays");
      return allHolidays.filter(
        (holiday) => !carrierHolidays.find((ch) => ch.holidayId === holiday.id),
      );
    },
    {
      enabled: openDialog,
      onSuccess: (filteredHolidays) => {
        setHolidays(filteredHolidays);
      },
      onError: (error) => {
        console.error("Unable to fetch holidays", error);
      },
    },
  );

  const addCarrierHolidayMutation = useMutation(
    (payload) => postRequestUI("/carrier/holidays", payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["carrierHolidays", carrierId]);
        setOpenDialog(false);
        resetForm();
      },
      onError: () => console.error("Unable to add holiday to carrier"),
    },
  );

  const deleteCarrierHolidayMutation = useMutation(
    (id) => deleteRequestUI(`/carrier/holidays/${id}`),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["carrierHolidays", carrierId]),
      onError: () => console.error("Unable to delete carrier holiday"),
    },
  );

  const handleAddCarrierHoliday = () => {
    if (!selectedHoliday) {
      return;
    }
    const data = {
      carrierId,
      holidayId: selectedHoliday.id,
      pickup,
      delivery,
    };
    addCarrierHolidayMutation.mutate(data);
  };

  const handleDeleteCarrierHoliday = (id) =>
    deleteCarrierHolidayMutation.mutate(id);

  const resetForm = () => {
    setSelectedHoliday({});
    setPickup(false);
    setDelivery(false);
  };

  const holidayTableData = {
    headers: ["Holiday Name", "Pickup", "Delivery", "Actions"],
    body: [
      (holiday) => holiday.name,
      (holiday) => <Checkbox checked={holiday.pickup} readOnly />,
      (holiday) => <Checkbox checked={holiday.delivery} readOnly />,
      (holiday) => (
        <IconButton onClick={() => handleDeleteCarrierHoliday(holiday.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    ],
  };
  return (
    <Grid container>
      {isFetchingCarrierHolidays && <LinearProgress />}
      <TableContainer component={Paper} sx={sharedStyles.paper}>
        <div style={{ padding: 15 }}>
          <PageTitle title="Carrier Holidays" />
        </div>
        <Button
          sx={sharedStyles.addButton}
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => setOpenDialog(true)}
        >
          Add Holiday
        </Button>
        <TableComponent
          headers={holidayTableData.headers}
          rowData={carrierHolidays}
          cellComponents={holidayTableData.body}
        />
      </TableContainer>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        TransitionComponent={Transition}
      >
        <DialogAppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenDialog(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {selectedHoliday?.id && (
              <Button
                autoFocus
                color="inherit"
                onClick={handleAddCarrierHoliday}
                disabled={addCarrierHolidayMutation.isLoading}
              >
                Save{" "}
                {addCarrierHolidayMutation.isLoading && (
                  <CircularProgress size={24} />
                )}
              </Button>
            )}
          </Toolbar>
        </DialogAppBar>
        <DialogContent>
          {fetchHolidaysError && (
            <Alert severity="error">Unable to fetch holidays.</Alert>
          )}
          <form style={sharedStyles.container}>
            <FormControl sx={{ marginBottom: 2, width: "25%" }}>
              <InputLabel>Holiday</InputLabel>
              <Select
                value={selectedHoliday || ""}
                onChange={(e) => setSelectedHoliday(e.target.value)}
                renderValue={(selected) => (selected ? selected.name : "")}
              >
                {holidays.map((holiday) => (
                  <MenuItem value={holiday} key={holiday.id}>
                    {holiday.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid
              container
              direction="column"
              sx={{ marginTop: theme.spacing(2) }}
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={pickup}
                      onChange={(e) => setPickup(e.target.checked)}
                    />
                  }
                  label="Pickup"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={delivery}
                      onChange={(e) => setDelivery(e.target.checked)}
                    />
                  }
                  label="Delivery"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default CarrierHolidaysTab;
