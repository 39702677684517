import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import useTheme from "@mui/material/styles/useTheme";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import DialogAppBar from "../components/DialogAppBar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { useQuery, useMutation } from "react-query";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import dompurify from "dompurify";

import { postRequestUI, putRequestUI } from "common-utils/utils/api";
import { ButtonProgress, SharedStyles } from "@shared/components/lib/index";
import _ from "lodash";
import { Snackbar, Alert } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const RetailerFAQForm = ({ faq, retailerId, onSave, handleClose }) => {
  const theme = useTheme();
  const sharedStyles = SharedStyles(theme);
  const [faqData, setFaqData] = useState(faq);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const sanitizer = dompurify.sanitize;
  const modules = {
    toolbar: [["bold", "italic", "underline"], [{ list: "ordered" }]],
  };

  const validate = () => {
    if (!faqData.title) {
      setErrorMessage("FAQ title is required");
      setShowError(true);
      return false;
    }
    if (!faqData.text) {
      setErrorMessage("FAQ text is required");
      setShowError(true);
      return false;
    }
    return true;
  };

  const handleAddFaq = () => {
    if (!validate()) {
      return;
    }
    const data = {
      title: faqData.title,
      text: sanitizer(faqData.text),
      retailerId,
    };
    createRetailerFaqMutation.mutate(data);
  };

  const handleUpdateFaq = () => {
    if (!validate()) {
      return;
    }
    const data = {
      id: faqData.id,
      title: faqData.title,
      text: sanitizer(faqData.text),
    };
    updateRetailerFaqMutation.mutate(data);
  };

  const createRetailerFaqMutation = useMutation(createRetailerFaq, {
    onSuccess: () => {
      onSave();
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
    onSettled: () => {
      handleClose();
    },
  });

  const updateRetailerFaqMutation = useMutation(updateRetailerFaq, {
    onSuccess: () => {
      onSave();
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
    onSettled: () => {
      handleClose();
    },
  });

  async function createRetailerFaq(data) {
    await postRequestUI(`retailer/faq/${retailerId}`, data);
  }

  async function updateRetailerFaq(data) {
    await putRequestUI(`retailer/faq/${data.id}`, data);
  }

  const submit = () => {
    if (!!faq) {
      handleUpdateFaq();
    } else {
      handleAddFaq();
    }
  };

  const setButtonValue = () => {
    return !!faq ? "Edit FAQ" : "Save FAQ";
  };

  return (
    <>
      <Dialog
        fullScreen
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogAppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Button
              data-testid="add_faq_btn"
              autoFocus
              color="inherit"
              onClick={submit}
            >
              {setButtonValue()}
            </Button>
          </Toolbar>
        </DialogAppBar>
        <DialogContent>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={showError}
            autoHideDuration={3000}
            onClose={() => setShowError(false)}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
          <form style={sharedStyles.container}>
            <FormControl sx={sharedStyles.formControl}>
              <Typography variant="h3" component="h4">
                {!!faq ? "Edit FAQ" : " Create New FAQ"}
              </Typography>

              <TextField
                data-testid="title_field"
                id="title"
                variant="standard"
                label="Title"
                value={faqData && faqData.title ? faqData.title : ""}
                onChange={(e) =>
                  setFaqData({
                    ...faqData,
                    title: e.target.value,
                  })
                }
              />
              <>
                <ReactQuill
                  style={{
                    marginTop: "5px",
                    borderRadius: "5px",
                    maxHeight: "300px",
                  }}
                  modules={modules}
                  value={faqData && faqData.text ? faqData.text : ""}
                  placeholder="Text"
                  onChange={(e) =>
                    setFaqData({
                      ...faqData,
                      text: sanitizer(e),
                    })
                  }
                />
              </>
            </FormControl>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default RetailerFAQForm;
